export const IconBadgeUpd = () => (
  <svg width="58" height="54" viewBox="0 0 58 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.754907 35.8274C-0.607703 33.8283 -0.0750647 31.1142 1.94459 29.7654L42.0321 2.99335C44.0518 1.64455 46.7936 2.17179 48.1562 4.17098L57.1692 17.3946C58.5318 19.3937 57.9992 22.1078 55.9795 23.4566L15.892 50.2286C13.8723 51.5774 11.1305 51.0502 9.76786 49.051L0.754907 35.8274Z"
      fill="#C12525"
    />
    <path
      d="M0.754907 35.8274C-0.607703 33.8283 -0.0750647 31.1142 1.94459 29.7654L42.0321 2.99335C44.0518 1.64455 46.7936 2.17179 48.1562 4.17098L57.1692 17.3946C58.5318 19.3937 57.9992 22.1078 55.9795 23.4566L15.892 50.2286C13.8723 51.5774 11.1305 51.0502 9.76786 49.051L0.754907 35.8274Z"
      stroke="#303030"
    />
    <path
      d="M12.3263 26.696L15.6626 24.5007L23.3616 36.2011C23.956 37.1044 24.1424 38.104 23.9208 39.1999C23.7011 40.2872 23.1344 41.1315 22.2207 41.7327L18.8687 43.9383C17.9551 44.5395 16.9546 44.7303 15.8673 44.5105C14.7818 44.2821 13.9384 43.711 13.3372 42.7973L5.63814 31.0969L8.97441 28.9016L16.6734 40.602C16.9689 41.051 17.3867 41.3335 17.9268 41.4494C18.4722 41.5619 18.9772 41.4653 19.4419 41.1595C19.9066 40.8538 20.1926 40.4299 20.2998 39.888C20.4123 39.3426 20.3208 38.8454 20.0254 38.3964L12.3263 26.696ZM28.8854 37.3472L18.991 22.3105L25.6792 17.9096C26.5929 17.3084 27.5925 17.122 28.678 17.3504C29.7688 17.5754 30.613 18.1421 31.2108 19.0506L32.3136 20.7265C32.9148 21.6402 33.1029 22.6424 32.878 23.7332C32.6548 24.8152 32.0863 25.6569 31.1726 26.2581L27.8207 28.4637L32.2216 35.1519L28.8854 37.3472ZM26.7282 26.8034L28.4042 25.7006C28.8584 25.4017 29.1418 24.9796 29.2543 24.4342C29.3685 23.8802 29.2762 23.3761 28.9773 22.9218L27.8745 21.2459C27.5756 20.7916 27.1526 20.5126 26.6055 20.4088C26.0601 20.2963 25.5603 20.3895 25.1061 20.6884L23.4301 21.7912L26.7282 26.8034ZM42.2383 28.5608L32.3439 13.5242L39.0321 9.12324C39.9458 8.52202 40.9454 8.33561 42.0309 8.56403C43.1216 8.789 43.9659 9.35573 44.5637 10.2642L50.0674 18.6283C50.6618 19.5316 50.8482 20.5312 50.6266 21.6271C50.4069 22.7144 49.8401 23.5587 48.9265 24.1599L42.2383 28.5608ZM44.4717 24.6896L46.1477 23.5868C46.6124 23.281 46.8983 22.8572 47.0056 22.3152C47.1181 21.7699 47.0266 21.2727 46.7312 20.8237L41.2274 12.4595C40.9285 12.0053 40.5055 11.7262 39.9584 11.6224C39.413 11.5099 38.9132 11.6031 38.459 11.902L36.783 13.0048L44.4717 24.6896Z"
      fill="white"
    />
  </svg>
);
