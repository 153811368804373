export const IconLotteryNotNew = () => (
  <svg width="123" height="107" viewBox="0 0 123 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64.2413 27.9058L83.6526 65.3427C86.7288 71.2755 84.4632 78.6179 78.5965 81.7287L41.5762 101.359C35.7094 104.469 28.4487 102.178 25.3725 96.2455L5.96117 58.8085C2.88498 52.8758 5.15062 45.5333 11.0173 42.4225L48.0362 22.7935C53.9029 19.6827 61.1651 21.973 64.242 27.9072L64.2413 27.9058Z"
      fill="#252528"
    ></path>
    <path
      d="M50.1686 24.3818L11.0981 45.0987C6.96142 47.2921 5.36632 52.4615 7.53538 56.6448L28.0217 96.1549C30.1908 100.338 35.3026 101.951 39.4394 99.7578L78.5098 79.0409C82.6465 76.8474 84.2416 71.678 82.0726 67.4948L61.5862 27.9846C59.4172 23.8013 54.3053 22.1883 50.1686 24.3818Z"
      fill="#B38DBF"
    ></path>
    <path
      d="M35.5211 100.839C32.4381 100.839 29.4562 99.1516 27.9291 96.2059L7.44642 56.695C5.25587 52.469 6.87395 47.2298 11.0513 45.0146L50.1225 24.3014C52.1463 23.2287 54.4631 23.0176 56.6436 23.7055C58.8257 24.3934 60.6105 25.9002 61.6712 27.9469L82.1539 67.4577C83.2146 69.5044 83.4251 71.8473 82.7432 74.0522C82.063 76.2589 80.5729 78.0638 78.549 79.1365L39.4779 99.8497C38.2134 100.521 36.8563 100.837 35.5194 100.837L35.5211 100.839ZM54.086 23.5063C52.7525 23.5063 51.429 23.8332 50.2134 24.4768L11.1422 45.19C7.05916 47.3541 5.47813 52.4741 7.61984 56.6031L28.1025 96.114C30.2425 100.243 35.3072 101.842 39.3903 99.676L78.4614 78.9628C80.4398 77.9139 81.8946 76.1499 82.5597 73.9943C83.2247 71.8387 83.021 69.5503 81.9838 67.5497L61.5012 28.0388C60.464 26.0382 58.7196 24.567 56.588 23.8928C55.7663 23.634 54.9245 23.5046 54.0877 23.5046L54.086 23.5063Z"
      fill="#303030"
    ></path>
    <path
      d="M58.544 64.7917C61.0313 63.5897 64.0084 64.6529 65.1954 67.1666C66.3824 69.6803 65.3278 72.6925 62.8405 73.8945C60.3532 75.0965 57.3761 74.0332 56.1891 71.5195C55.0021 69.0059 56.0567 65.9936 58.544 64.7917Z"
      fill="#252528"
    ></path>
    <path
      d="M39.1288 75.0612C41.6161 73.8592 44.5932 74.9224 45.7803 77.4361C46.9673 79.9498 45.9127 82.962 43.4254 84.164C40.9381 85.366 37.961 84.3028 36.7739 81.7891C35.5869 79.2754 36.6415 76.2632 39.1288 75.0612Z"
      fill="#252528"
    ></path>
    <path
      d="M45.7709 40.0026C48.2582 38.8006 51.2353 39.8638 52.4223 42.3775C53.6093 44.8912 52.5547 47.9034 50.0674 49.1054C47.5801 50.3074 44.603 49.2442 43.416 46.7305C42.229 44.2168 43.2836 41.2046 45.7709 40.0026Z"
      fill="#252528"
    ></path>
    <path
      d="M26.4275 50.3854C28.9148 49.1834 31.8919 50.2466 33.0789 52.7603C34.2659 55.274 33.2114 58.2862 30.724 59.4882C28.2367 60.6902 25.2596 59.627 24.0726 57.1133C22.8856 54.5996 23.9402 51.5874 26.4275 50.3854Z"
      fill="#252528"
    ></path>
    <path
      d="M32.8172 62.7135C35.3046 61.5115 38.2817 62.5748 39.4687 65.0885C40.6557 67.6021 39.6011 70.6144 37.1138 71.8164C34.6265 73.0183 31.6494 71.9551 30.4624 69.4414C29.2753 66.9277 30.3299 63.9155 32.8172 62.7135Z"
      fill="#252528"
    ></path>
    <path
      d="M52.2385 52.4206C54.7258 51.2186 57.7029 52.2818 58.8899 54.7955C60.077 57.3092 59.0224 60.3214 56.5351 61.5234C54.0477 62.7254 51.0706 61.6621 49.8836 59.1485C48.6966 56.6348 49.7512 53.6225 52.2385 52.4206Z"
      fill="#252528"
    ></path>
    <path
      d="M68.2155 58.3439C67.6363 58.3439 67.0747 58.0261 66.7875 57.4711L53.933 32.6747C53.5198 31.8776 53.8245 30.8935 54.6111 30.4756C55.3992 30.0577 56.3725 30.3659 56.7857 31.1613L69.6402 55.9577C70.0534 56.7547 69.7487 57.7389 68.9621 58.1568C68.7228 58.2826 68.4675 58.3439 68.2155 58.3439Z"
      fill="#DFD8EC"
    ></path>
    <path
      d="M11.5231 45.0017L36.6407 94.0255L82.8342 69.2482C82.9865 70.0581 83.338 72.4727 82.1129 75.1081C81.0066 77.4867 79.2393 78.706 78.5263 79.1443C65.5809 86.0883 52.6355 93.0322 39.6901 99.9762C39.1277 100.284 36.6926 101.538 33.6634 100.737C30.1421 99.8049 28.5806 96.827 28.3413 96.3509C21.5548 83.1052 14.7683 69.8595 7.98176 56.6138C7.73406 56.136 6.26128 53.1786 7.4713 49.7126C8.54074 46.6525 10.9675 45.2945 11.5231 45V45.0017Z"
      fill="#A479B1"
    ></path>
    <path
      d="M119.296 31.2372L107.536 71.801C105.672 78.2293 98.9979 81.9183 92.6411 80.0337L52.5288 68.1412C46.172 66.2566 42.5241 59.5074 44.3878 53.0791L56.1478 12.5153C58.0115 6.08699 64.6856 2.39801 71.0424 4.28265L111.155 16.1751C117.511 18.0597 121.159 24.809 119.296 31.2372Z"
      fill="#252528"
    ></path>
    <path
      d="M111.238 19.1061L69.4113 6.70546C64.9827 5.3925 60.3401 7.95857 59.0418 12.437L46.7791 54.7343C45.4808 59.2127 48.0183 63.9075 52.4468 65.2205L94.2735 77.6212C98.702 78.9341 103.345 76.368 104.643 71.8897L116.906 29.5923C118.204 25.1139 115.666 20.4191 111.238 19.1061Z"
      fill="#FAFF00"
    ></path>
    <path
      d="M90.4018 51.9136C93.0167 52.7961 94.4287 55.6534 93.5544 58.2946C92.6817 60.9357 89.8529 62.362 87.2396 61.4794C84.6247 60.5969 83.2127 57.7412 84.087 55.0984C84.9597 52.4573 87.7884 51.031 90.4018 51.9136Z"
      fill="#252528"
    ></path>
    <path
      d="M69.38 45.6597C71.995 46.5422 73.4069 49.3995 72.5326 52.0407C71.6599 54.6818 68.8328 56.1081 66.2178 55.2255C63.6029 54.343 62.1909 51.4857 63.0652 48.8445C63.9379 46.2034 66.7651 44.7771 69.38 45.6597Z"
      fill="#252528"
    ></path>
    <path
      d="M96.8969 29.7495C99.5119 30.632 100.924 33.4878 100.05 36.1305C99.1768 38.7717 96.3481 40.1979 93.7347 39.3154C91.1198 38.4328 89.7078 35.5755 90.5821 32.9344C91.4549 30.2932 94.2836 28.867 96.8969 29.7495Z"
      fill="#252528"
    ></path>
    <path
      d="M75.8848 23.5073C78.4997 24.3898 79.9117 27.2456 79.0374 29.8883C78.1647 32.5295 75.3359 33.9557 72.7226 33.0732C70.1077 32.1907 68.6957 29.3333 69.57 26.6922C70.4427 24.051 73.2714 22.6248 75.8848 23.5073Z"
      fill="#252528"
    ></path>
    <path
      d="M102.589 56.4459C102.439 56.4459 102.286 56.4249 102.136 56.3798C101.283 56.1264 100.793 55.2213 101.043 54.3582L108.831 27.4918C109.081 26.6286 109.976 26.1333 110.83 26.3866C111.683 26.6399 112.173 27.545 111.923 28.4082L104.135 55.2746C103.929 55.9845 103.286 56.4459 102.591 56.4459H102.589Z"
      fill="white"
    ></path>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.32}
    >
      <path
        d="M69.1333 6.35103L53.902 58.8985L104 73.7485C103.559 74.4329 102.171 76.398 99.497 77.411C97.0846 78.3258 94.9822 77.9498 94.1714 77.7617C80.1312 73.5995 66.0927 69.4389 52.0525 65.2766C51.4377 65.1004 48.8327 64.2805 47.196 61.5802C45.2946 58.4412 46.1925 55.2242 46.3433 54.7177C50.4594 40.5199 54.5738 26.3238 58.6898 12.126C58.8356 11.6127 59.7854 8.47197 63.0119 6.85754C65.8598 5.43285 68.5318 6.17146 69.1316 6.35441L69.1333 6.35103Z"
        fill="#C4B8DA"
      ></path>
    </g>
    <g id="new">
      <path stroke="#303030"></path>
    </g>
  </svg>
);
