export default {
  system: {
    user: 'User',
    level: 'Level',
    levelShort: 'Lvl',
    coins: 'coins',
    close: 'Close',
    friends: 'Friends',
    continue: 'Continue',
    seconds: 'sec',
    wallet: {
      connected: 'connected',
      notConnected: 'not connected',
      connectInAirdrop: 'Connect in Airdrop',
      yourWallet: 'Your wallet:',
    },
    button: {
      deposit: 'Send Transaction',
      check: 'Check',
      refresh: 'Refresh Wallet',
      connect: 'Connect Wallet',
      disconnect: 'Disconnect',
    },
  },
  menu: {
    meerkatCoins: 'Meerkat Coins',
    way: 'Way',
    boosts: 'Boosts',
    missions: 'Missions',
    friends: 'Friends',
    diary: 'Diary',
    airdrop: 'Airdrop',
    lottery: 'Lottery',
    world: 'World',
  },
  welcome: {
    okay: 'Okay',
    startGame: 'Start Game',
    useInsights: {
      heading: 'Use Insights 🧠',
      text: 'Tap on your meerkat to activate an Insight boost! This special ability increase your earnings, make the most of it while it lasts to maximize your gains!',
    },
    makeUpgrades: {
      heading: 'Make Upgrades 🔋',
      text: 'Level up your Meerkat with upgrades! From Insights and Mental Energy to Physical Strength and Stress Resistance, these upgrades will push your Meerkat beyond its limits.',
    },
    inviteFriends: {
      heading: 'Invite Friends ✨',
      text: 'Bring your friends onboard! Completing missions together will unlock new boosts, enhance gameplay and significantly multiply your passive income. The more friends - the better!',
    },
    meerkatCoins: {
      heading: 'Meerkat Coins 💸',
      text: 'Every step you take and every game you play brings your Meerkat closer to treasure. The more active you are throughout the day, the more coins you get.',
    },
  },
  friends: {
    bestFriends: 'Best friends',
    and: 'and',
    yourReward1: 'Your',
    yourReward2: 'reward from the first-line',
    yourReward3: 'referral claims, and',
    yourReward4: 'from the second-line referrals.',
    inviteFriends: 'Invite friends!',
    inviteFriend: 'Invite a friend',
    listOfYourFriends: 'List of your friends',
    linkCopied: 'The invitation link has been copied to your clipboard!',
    claim: 'Get 24h Ref Income',
    makeSpiritFriends: 'Make spirit friends',
    playWithMe: 'Join me and play to earn coins 💰!\n' + 'This is the entrance to the Worried Meerkats Universe!',
    spirit: {
      makeFriends: 'Make spirit friends',
      intro:
        "If you can't/don't want to invite friends, you can always surround yourself with spirit friends who will help improve your income and unlock additional Boosts",
      friend_one: 'spirit friend',
      friend_other: 'spirit friends',
      willHelpImprove: 'Will help improve income cards and quests that require friends',
      buyFor: 'Buy for',
      congratulations: 'Congratulations!',
      youBought_one: 'You bought {{count}} spirit friend',
      youBought_other: 'You bought {{count}} spirit friends',
      friendsCount: 'Spirit friends count',
    },
  },
  leaderboard: {
    beTheFirst: 'Be the first!',
    leagues: {
      wooden: 'Wooden League',
      stone: 'Stone League',
      iron: 'Iron League',
      bronze: 'Bronze League',
      silver: 'Silver League',
      golden: 'Golden League',
      platinum: 'Platinum League',
      osmium: 'Osmium League',
      palladium: 'Palladium League',
      rhodium: 'Rhodium League',
      iridium: 'Iridium League',
      ether: 'Ether League',
      calmMeerkat: 'Calm Meerkat League',
    },
  },
  flocks: {
    single: 'Single',
    flocks: 'Flocks',
    create: 'Create',
    update: 'Update',
    placeholder: '@ Telegram channel beginning with @',
    joinOurTopCommunities: 'Join Our Top Communities!',
    becomePartOfOurMostActiveCommunities:
      "Become part of our most active flocks and climb the rankings together! The position of each flock is based on the total number of coins of the flock creator's friends and joined participants.",
    createYourOwnCommunity: 'Create Your Own Community',
    ifYouAreMajorInfluencer:
      'If you’re a major influencer, you can take the lead and build your own community! To get started, you’ll need to have over',
    fiveHundredsInvitedFriends: '500 invited friends completed at least one task',
    new: 'New',
    top: 'Top',
  },
  tasks: {
    moreCoins: 'Do you want even more coins?',
    showPromoTasks: 'Show promo tasks',
  },
  stats: {
    statistics: 'Statistics',
    claimsDone: 'Claims Done',
    tasksDone: 'Tasks Done',
    friendsCount: 'Friends Count',
    total: 'Total',
    friendsReward: 'Friends Reward',
    friendsL1: 'Friends L1',
    whoCompletedTasks: 'Who completed tasks',
    whoConnectedWallet: 'Who connected wallet',
    whoBookedNFTs: 'Who booked NFT',
    lottery: 'lottery winnings',
    friendsLevel1LotteryReward: 'for L1 friends',
  },
  settings: {
    settings: 'Settings',
    chooseLanguage: 'Choose language',
  },
  farming: {
    claimed: {
      includingBonus: 'Including x{{multiplier}} bonus due',
      stressResistance: 'to stress resistance!!',
      claimed: 'Claimed!',
    },
    stopped: {
      welcome: "Welcome, you've landed at Meerkat Coins!",
      fuelUp: "Fuel up your meerkat turbo boosts, and let's upgrade for the journey ahead!",
      startGame: 'Start Game',
      tribeNotCult: 'And just so you know, this is a tribe, not a cult. Got it? Perfect.',
      walkInterrupted: 'The walk was interrupted due to a lack of mental energy!',
      claimRewardPrompt: 'Claim your round reward and let your meerkat gear up for the next round',
      claimRewards: 'Claim Rewards',
      mentalEnergyInsights: 'Mental energy and insights will be recharged for the next round',
    },
    progress: {
      roundBalance: 'Round balance:',
      roundTime: 'Round time:',
      roundMentalEnergy: 'round mental energy',
    },
  },
  boosts: {
    boosts: 'Boosts',
    upgrade: 'Upgrade',
    levelUp: 'Level Up!',
    toLevel: 'to level',
    for: 'for',
    run: 'run',
    resetBoosts: 'Reset boosts',
    yourBoostsHaveBeenReset: 'Your boosts have been reset',
    byUpgradingYourBoosts: {
      part1:
        "By upgrading your boosts, you're not just powering up your meerkat—you're also boosting your extra earnings! Once certain thresholds are hit, your ",
      highlight: 'income from completing MISSIONS will rise.',
      part2: "It's a WIN-WIN!",
    },
    calm: {
      mentalEnergy: 'Mental Energy',
      duration: '{{hours}}h {{minutes}}m {{seconds}}s',
      inRound: 'in round',
    },
    cloud: {
      insights: 'Insights',
      upToTimes: 'up to 3 times per each round:',
      speedX: 'speed x',
    },
    luck: {
      stressResistance: 'Stress Resistance',
      percentChance: '% percent chance of reward x',
      reward_description: '{{probability}}% of x{{multiplier}} reward',
    },
    speed: {
      physicalStrength: 'Physical Strength',
      profit: 'profit',
      coinsPerSec: 'coins/sec',
    },
    conditions: {
      needSolvedTasks: 'need solved tasks:',
      needFriends: 'need friends:',
      needClaims: 'need claims:',
    },
    vials: {
      buyVial: 'Buy vial of mental energy',
      intro: 'The Vial gives additional Mental Energy time to increase round time',
      extraHoursCount: 'Extra hours count',
      extraHour_one: 'extra hour per each round',
      extraHour_other: 'extra hours per each round',
      vialOfMentalEnergy_one: 'vial of mental energy',
      vialOfMentalEnergy_other: 'vials of mental energy',
      youBought_one: 'You bought {{count}} vial',
      youBought_other: 'You bought {{count}} vials',
    },
  },
  diary: {
    diaryOfMeerkat: "Meerkat's Diary",
    pages: {
      1: 'The new dawn illuminated the roofs of the houses, and the entire city slowly began to wake up, tossing and cursing, amidst the cacophony of alarm clocks. Traffic jams of early birds formed in the morning "noisy" mist - they honked and squeezed together to find a more comfortable position on the road. Coffee lovers who had already formed a line at the cash register buried their faces in the flickering screens.',
      2: 'Sleepy but already worried creatures jostled in similar postures somewhere underground, in subway cars. Their expressions were troubled by looming tasks and news sent to them via smartphones. Wake up and sing, the little town in the WEB3 universe! Get up and sing! But why are you singing such a tense song?',
      3: {
        above:
          'Jake, a meerkat, lived in the midst of such a frantic urban flow! Jake is neither fish nor fowl, as the saying goes. An ordinary average creature, with the same coloration as many others, of average height and weight. At first glance, the overall impression is unremarkable.',
        fingers: '4 fingers\n' + 'regular hand',
        common: 'Meerkat common\n' + 'Nothing special',
        below: 'Believe it or not, Jake noticed a bright glow in the forest on the outskirts of town one day.',
        centimeters: 'cm',
      },
      4: {
        above:
          'There was a bright flash, followed by several bright pulsations, and then... darkness. Meerkats are distinguished by their meerkat curiosity, which occasionally, if not frequently, annoys other inhabitants of the WEB3 space.',
        below:
          "And, of course, Jake's insatiable curiosity took over his restless mind right away. He quickly shut down his laptop, ignoring the business plan's past deadlines. The creature hastily exited the abhorrent work in search of an answer to the question, \"What the hell is this?",
      },
      5: {
        above:
          'Jake dashed into the heart of the forest, where the glow intensified. The closer he got, the more terrified he was. His entire body ached with nervousness, but he was used to it, right, restless Jake?',
        below:
          'Jake had already fled the city and was cautiously making his way through the forest, unable to recognize himself due to fear. He made his way toward a distant, pulsating light that became brighter with each step. The source of the light was eventually discovered to be a cave. A cave?...',
      },
      6: 'Jake would have gladly knocked or rung a bell out of politeness, but instead, tripping over a stone, he fell into its depths...',
      7: {
        above:
          "Don't ask how he managed to land softly and keep his head. The most intriguing aspect was that he discovered a strange floating box deep at the bottom of the cave...",
        below:
          'It was so intriguing to Jake that he sat down next to it and began exploring it, or rather, investigate it.',
      },
      8:
        "The box, as you've already understood, was nothing but the TimeBox. Yes, indeed, that very TimeBox.\n" +
        'Then, in an instant, the TimeBox unlocked, flooding Jake with a torrent of deep understanding, inner assurance, calmness, and tranquility. Jake, or more precisely his consciousness, found the universe and the universe found him - they merged to form one.',
      9:
        'As soon as Jake became aware of this extraordinary sensation, sweat started to appear on his already perspiring fur.\n' +
        'Deep inside the cave, he could hear endless waves gently rolling. He felt the chill of the stone around him, and a light breeze caressed his muzzle tenderly. Everything stopped, and it all came together in perfect harmony, like lovely music and the calming rustle of leaves. Jake paid close attention and realized at once that it had been his breath the entire time.',
      10: 'His entire body began to feel warm as if a creature were sitting by a small, crackling fire. Finally, he came to the conclusion that none of his issues, concerns, or anxieties had as much influence over him as he had believed. He could control them and handle them. And the moment he had this thought, it had already become a part of him. With just one thought, he acquired wisdom and strength. And the idea took physical form. And at this point, he was determined to understand it completely, right down to the core.',
      11: {
        above:
          'Thoughts are formed by intention. Decisions are influenced by thoughts. Our choices influence our actions. And our reality is what we do. We can change with just one thought. In a place of darkness, a ray of light inspires hope. Jake will never again be the same worried meerkat. He is aware of it, as are we and the energy that resides in the enigmatic TimeBox.',
        below:
          'Jake opened his eyes. The same terrifying and dark cave was in front of him. While Jake had changed, the cave had not changed. He was now fully aware of what to do. He got up, put down the time box, thanked him sincerely inside himself and went upstairs, out of the cave.',
      },
      12:
        "He who seeks shall find, he who knows where to go is aware of the favorable wind! Don't believe me? Ask Jake!\n" +
        '\n' +
        'Oh, he stepped outside. The world looked different: sounds of nature, birdsong, light and shadows. It seemed even time had slowed down.',
      13:
        '"Did I consume something intoxicating?".\n' +
        'Jake thought.\n' +
        '"No, l\'ve just finally awakened from slumber," answered the wise inner voice of the meerkat who had become wise.\n' +
        '\n' +
        "Now, Jake was constantly honing his new abilities: he meditated, developed control, improved his ability to maintain focus, and much more. He also frequently explored the magical cave in search of TimeBoxes. They were there in great numbers and came in a variety of colors and meanings. Jake received new, special knowledge and Wisdom from each of them. Thus, Jake's awareness grew, and along with it, a plan matured.",
      14: {
        above:
          "Only two things usually warm the restless meerkat's soul: its fur and Lady Luck.\n" +
          "Without luck, a meerkat is just prey for a hawk. Without luck, it's merely a ball of fur in these lonely prairies.",
        below:
          'And it was her that Jake decided to call upon, on the path to realizing his plan. To luck. The plan was simple - to enhance the mindfulness and well-being of all WEB3 meerkats. Bang bang... and suddenly, Light, music, and cheerful singing in the depths of the forest captured the attention of the townsfolk.',
      },
      15: {
        above:
          'What would awaken the meerkats from their restless activities? A mystery? Knowledge? The feeling of gain? More and more whispers about a miraculous lottery and its fantastic prizes and benefits could be heard here and there.',
        below:
          'This was something new! Excitement, curiosity, and the challenge of luck gave the creatures confidence, and they set out to test their luck firsthand. But there was a condition - only those who sat with a TimeBox for a short while could try their luck. Oh, that clever Jake. The animals, Without realizing it, began to touch wisdom, mindfulness, and calmness.',
      },
      16: {
        above:
          'Thanks to the lottery, many discovered the magical TimeBoxes and their power, delved into the secrets of inner tranquility and boundless wisdom, and also received various useful rewards and bonuses.',
        below:
          'When the student is ready, the teacher appears!\n' +
          '\n' +
          'And so, Jake gained new friends and followers, and why? Because luck + mindfulness = a powerful combination. Many have experienced the power of the TimeBox, the power of rest.',
      },
      17: 'The entire world learned about it after they tried it themselves and told their friends, who then told their friends, and so on.',
      tbc: 'To be continued...',
    },
  },
  popups: {
    checkTask: {
      doIt: 'Do it',
      join: 'Join',
      check: 'Check',
      taskIsNotCompleted: "Task isn't completed",
      joinMysteryCaveTask:
        'A browser page (Safari, Chrome, etc.) will open for registration. Enter your email, and you’ll receive a one-time password in your inbox. Just copy the password, return to the browser page, and enter it to log in.',
    },
    taskComplete: {
      missionCompleted: 'Mission Completed!',
      nowYouAreFlockMember: "Now you're a flock member!",
    },
  },
  notifications: {
    debug: {
      taps_away: 'You are now {{count}} taps away from debug mode',
      on: 'Debug mode is on!',
    },
    http: {
      unexpected_error_title: "We're facing an unexpected error 🚧",
      unexpected_error_subtitle:
        "Could be something with the connectivity. We'll get your meerkat back on track in no time!",
    },
    farming: {
      coins_collected_title: 'Meerkat Coins collected! 🌱',
      coins_collected_subtitle: 'A new journey begins, watch your coins pump! 🚀',
      insights_activated_title: 'Earn more with Insights Activated! 🌱',
      insights_activated_subtitle: 'Meerkat Coins are rolling in faster than ever! 🏃‍♂️💨',
    },
    upgrade: {
      insight_title: 'Insight Upgrade Complete! ⚡️',
      insight_subtitle: 'Deeper dive - higher ascent, time to see the essence!💥',
      physical_strength_title: 'Physical Strength Upgrade Complete! 💪',
      physical_strength_subtitle: 'Strength is power, time to dominate!💥',
      mental_energy_title: 'Mental Energy Upgrade Complete! 🧠',
      mental_energy_subtitle: 'Energy is the key, time for opportunities! 💥',
      stress_resistance_title: 'Stress Resistance Upgrade Complete! 🍃',
      stress_resistance_subtitle: 'Fortune favors the strong, time to test your luck! 💥',
    },
    connection: {
      restored_title: 'Connection Restored',
      restored_subtitle: "You're back online! Connection to the internet is restored",
      lost_title: 'Connection Lost',
      lost_subtitle: "You're currently offline. Please check your internet connection!",
    },
    airdrop: {
      payment: {
        title: 'Meerkat Coin Airdrop 💎',
        subtitle: 'Confirm transaction in your wallet',
      },
      disconnect: {
        title: 'Meerkat Coin Airdrop 💎',
        subtitle: 'Reconnect your wallet to stay in the game.',
      },
      deposit: {
        title: 'Meerkat Coin Airdrop 💎',
        subtitle: 'You have sent: {{amount}} TON',
      },
      deposit_done: {
        title: 'Meerkat Coin Airdrop 💎',
        subtitle: 'You have sent the required amount of {{amount}} TON',
      },
    },
    nft: {
      payment: {
        title: 'Meerkat Coin NFT 💎',
        subtitle: 'Confirm transaction in your wallet',
      },
      disconnect: {
        title: 'Meerkat Coin NFT 💎',
        subtitle: 'Reconnect your wallet to stay in the game.',
      },
      deposit: {
        title: 'Meerkat Coin NFT 💎',
        subtitle: 'You have sent: {{amount}} TON',
      },
      deposit_done: {
        title: 'Meerkat Coin NFT 💎',
        subtitle: 'You have sent the required amount of {{amount}} TON',
      },
    },
  },
  airdrop: {
    heading: {
      title: 'Meerkat Coin Airdrop',
      sub_title_date: 'Reserve your spot today!',
      sub_title_disconected: 'The Airdrop is just around the corner!',
    },
    text: {
      line1: 'To participate in our airdrop you need to follow these steps:',
      line2: "We're in the farming stage, so the more coins you earn, the better the rewards will be for the airdrop!",
      line3:
        'The official Airdrop date will be revealed very soon in our Telegram channel. Don’t miss out, stay sharp, and keep climbing, meerkats!',
      line4:
        'The tokens will land straight in your wallet, and soon you’ll be able to trade them on the leading exchanges. Whether you decide to sell or hold, the power’s in your paws!',
    },
  },
  airdropTickets: {
    heading: {
      title: 'Meerkat Coin',
      sub_title: 'Big Lake Airdrop is in progress!',
    },
    yourWallet: 'Your airdrop wallet:',
    stats: {
      title: 'Your current tickets:',
      ticketsForWallet: 'For connected wallet',
      ticketsForNFTs: 'For NFTs',
      ticketsFromCoins: 'Converted from coins',
      ticketsTotal: 'Total tickets',
    },
    reminder: "Connected your wallet, have tickets but still haven't received the Drop to your Venom wallet? Find the details in the news and our chats! Hurry up before 15.03.2025",
    links: {
      connectWallet: "Still didn't connect your wallet? Do it here",
    },
    button: {
      getNow: 'convert',
      tickets: '{{price}} coins into {{count}}',
      ticketWord_interval: '(0)[tickets];(1)[ticket];(2-1000)[tickets];',
    },
  },
  lottery: {
    button: {
      myWins: 'My Winnings',
      other: 'All Draws',
      iAmIn: 'Yeah, I’m in!',
      tickets: 'tickets: 1 for {{price}} coins + {{bonusTickets}} for NFT',
    },
    heading: 'lottery',
    pitch1: 'Win daily prizes from 20 to 200 TON pool!',
    pitch2: "Get 10% of your L1 friends' TON winnings!",
    toParticipateItIsNecessary: 'To participate in the lottery it is necessary',
    toConfirmWallet: 'to confirm wallet in AIRDROP section',
    forEachNFT: '* for each NFT in World section you gain an additional free ticket in the lottery',
    youHaveEntered: 'You are taking part',
    theLottery: 'in the drawing!',
    pastLotteryResultsHere: 'Previous lottery draws',
  },
  cave: {
    coinsFromCave: 'Coins from Cave:',
    rarity: 'Rarity',
    time: 'Time',
  },
  nftTrophy: {
    heading: {
      title: 'Build Meerkat Cave',
      sub_title: 'Book "Trophy" NFT today!',
    },
    text: {
      // line1: 'To book the NFT follow these steps:',
      line1: 'UPD: booking completed',
      line2:
        'Hurry up to book limited NFT "Trophy" that gives bonuses: +5 coins per second after drop and an extra SECRET bonus.',
      line3:
        "On 25.10.2024, the Trophy DROP will happen (you'll receive 1 of 3 types). Then, the NFTs will be displayed in your personal cave in the app!",
      line4: "You make the transaction — the Trophy gets AIRDROP! It's that simple.",
    },
  },
  nftCave: {
    heading: {
      title: 'Build Meerkat Cave',
      sub_title: 'Book "Super Cave" NFT today!',
    },
    text: {
      // line1: 'To book the NFT follow these steps:',
      line1: 'UPD: booking completed',
      line2:
        'Hurry up to book limited NFT "Super Cave" that gives bonuses: +7 coins per second after drop and an extra SECRET bonus.',
      line3:
        "On 28.10.2024, the Super Cave DROP will happen (you'll receive 1 of 3 types). Then, the NFTs will be displayed in your personal cave in the app and on the NFT exchange!",
      line4: "You make the transaction — the Super Cave gets AIRDROP! It's that simple.",
    },
  },
  nftFireplace: {
    heading: {
      title: 'Build Meerkat Cave',
      sub_title: 'BOOK “FIREPLACE” NFT today!',
    },
    text: {
      // line1: 'To book the NFT follow these steps:',
      line1: 'UPD: booking completed',
      line2:
        'Hurry up to book limited NFT “Fireplace” that gives bonuses: +5 coins per second after drop and and an extra SECRET bonus.',
      line3:
        "On 05.11.2024, the Fireplace DROP will happen (you'll receive 1 of 3 types). Then, the NFTs will be displayed in your personal cave in the app and on the NFT exchange!",
      line4: "You make the transaction - the NFT Fireplace gets AIRDROP! It's that simple.",
    },
  },
  nftGameMachine: {
    heading: {
      title: 'Build Meerkat Cave',
      sub_title: 'Book "Game Machine" NFT today!',
    },
    text: {
      // line1: 'To book the NFT follow these steps:',
      line1: 'UPD: booking completed',
      line2:
        'Hurry up to book limited NFT "Game Machine" that gives bonuses: +7...+9 coins per second (depends on rarity) after drop and an extra SECRET bonus.',
      line3:
        "On 14.11.2024, the Game Machine DROP will happen (you'll receive 1 of 3 types). Then, the NFTs will be displayed in your personal cave in the app and on the NFT exchange!",
      line4: "You make the transaction — the Game Machine gets AIRDROP! It's that simple.",
    },
  },
  nftDesk: {
    heading: {
      title: 'Build Meerkat Cave',
      sub_title: 'Book "Desk" NFT today!',
    },
    text: {
      // line1: 'To book the NFT follow these steps:',
      line1: 'UPD: booking completed',
      line2:
        'Hurry up to book limited NFT "Desk" that gives bonuses: +8...+10 coins per second (depends on rarity) after drop and an extra bonus: +30% on the amount of your LOTTERY winnings!!!',
      line3:
        'On 21.11.2024, the Desk DROP will happen (1 of 3 options). Then, the NFTs will be displayed in your cave in the app and on the NFT exchange!',
      line4: "You make the transaction — the Desk gets AIRDROP! It's that simple.",
    },
  },
  nftBed: {
    heading: {
      title: 'Build Meerkat Cave',
      sub_title: 'Book "Sleeping Furniture" NFT today!',
    },
    text: {
      // line1: 'To book the NFT follow these steps:',
      line1: 'UPD: booking completed',
      line2:
        'Hurry up to book limited NFT Sleeping Furniture that gives bonuses: +5...+7 coins per second (depends on rarity) after drop and an extra bonus: RARE SOULBOX in the dapp!!!',
      line3:
        'On 28.11.2024, the Sleeping Furniture DROP will happen (1 of 3 options). Then, the NFTs will be displayed in your cave and on the NFT exchange!',
      line4: "You make the transaction — the Sleeping Furniture gets AIRDROP! It's that simple.",
    },
  },
  nftSofa: {
    heading: {
      title: 'Build Meerkat Cave',
      sub_title: 'Book "Sofa" NFT today!',
    },
    text: {
      // line1: 'To book the NFT follow these steps:',
      line1: 'UPD: booking completed',
      line2:
        'Hurry up to book limited NFT Sofa that gives bonuses: +8...+10 coins per second (depends on rarity) after drop and an extra bonus: +1 TICKET for BIG DROP!!!',
      line3:
        'On 09.12.2024, the Sofa DROP will happen (1 of 3 options). Then, the NFTs will be displayed in your cave and on the NFT exchange!',
      line4: "You make the transaction — the Sofa gets AIRDROP! It's that simple.",
    },
  },
  nftSouvenir: {
    heading: {
      title: 'Build Meerkat Cave',
      sub_title: 'Book "Souvenir" NFT today!',
    },
    text: {
      // line1: 'To book the NFT follow these steps:',
      line1: 'UPD: booking completed',
      line2:
        'Hurry up to book limited NFT Souvenir that gives bonuses: +5...+7 coins per second (depends on rarity) after drop and an extra bonus: +1 TICKET for BIG DROP (+2 if the rare one)!!!',
      line3:
        'On 22.12.2024, the Souvenir DROP will happen (1 of 3 options). Then, the NFTs will be displayed in your cave and on the NFT exchange!',
      line4: "You make the transaction — the Souvenir gets AIRDROP! It's that simple.",
    },
  },
  nftPoster: {
    heading: {
      title: 'Build Meerkat Cave',
      sub_title: 'Book "Movie poster" NFT today!',
    },
    text: {
      // line1: 'To book the NFT follow these steps:',
      line1: 'UPD: booking completed',
      line2:
        'Hurry up to book the limited NFT that gives bonuses: +5...+7 coins per second (by rarity) after drop and an extra bonus: access to the exclusive "Motivation" course!',
      line3:
        'On 31.12.2024, the Movie Poster DROP will happen (1 of 3 options). Then, the NFTs will be displayed in your cave and on the NFT exchange!',
      line4: "You make the transaction — the Movie Poster gets AIRDROP! It's that simple.",
    },
  },
  nftLighting: {
    heading: {
      title: 'Build Meerkat Cave',
      sub_title: 'Book "Lighting" NFT today!',
    },
    text: {
      // line1: 'To book the NFT follow these steps:',
      line1: 'UPD: booking completed',
      line2:
        'Hurry up to book the limited NFT that gives bonuses: +5...+7 coins per second (by rarity) after drop and an extra bonuses: access to the exclusive "Freedom from stress" course and +1 TICKET for BIG DROP (+2 if the rare one)!',
      line3:
        'On 27.01.2025, the Lighting DROP will happen (1 of 3 options), and the NFTs will be displayed!',
      line4: "You make the transaction — the Lighting gets AIRDROP! It's that simple.",
    },
  },
  // runningNewsLine: 'NEW: try your luck in the daily lottery! bet meerkat coin and win ton!',
  // runningNewsLine: 'URGENT NEWS: "Game Machine" NFT booking is now available in the World menu!'
  // runningNewsLine: 'URGENT NEWS: "Desk" NFT booking is now available in the World menu! It gives +30% on the amount of the LOTTERY winnings!!!'
  // runningNewsLine: 'URGENT NEWS: "Sleeping Furniture" NFT booking is now available in the World menu! It also gives RARE SOULBOX!!!'
  // runningNewsLine: 'URGENT NEWS: "Sofa" NFT booking is now available in the World menu! It also gives +1 TICKET for BIG DROP!!!'
  // runningNewsLine: 'URGENT NEWS: "Souvenir" NFT booking is now available in the World menu!'
  // runningNewsLine: 'URGENT NEWS: "Movie Poster" NFT booking is now available in the World menu! It also gives access to the exclusive "Motivation" course!'
  // runningNewsLine: 'URGENT NEWS: "Lighting" NFT booking is now available in the World menu! It also gives access to the exclusive "Freedom from stress" course and +1 TICKET for BIG DROP!'
  runningNewsLine: 'BIG LAKE AIRDROP IS IN PROGRESS! Find details on Airdrop tab!',
};
